.lightbox {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 8;
	background: rgba($brand-sec,.95);
	overflow: auto;
	display: none;

	// &.lightbox-half {
	// 	width: 50%;
	// 	height: 50%;
	// 	top: 50%;
	// 	left: 50%;
	// 	transform: translate(-50%,-50%);
	// }

	.lightbox-content {
		margin: 150px auto;

		@media screen and (max-width: $screen-md-max) {
			margin: 120px auto;
		}

		@media screen and (max-width: $screen-sm-max) {
			margin: 90px auto;
		}

		@media screen and (max-width: $screen-xs-max) {
			margin: 60px auto;
		}

		&.disabled {
			opacity: .3;
			pointer-events: none;
		}
	}

	.container {
		background: white;
		box-shadow: 0 0 10px rgba(0,0,0,.8);
	}

	.close-button {
		position: fixed;
		top: 20px;
		right: 20px;
		color: $brand-sec;
		font-size: 2.2rem;
		background: white;
		border-radius: 50%;
		width: 35px;
		height: 35px;
		text-align: center;

		@media screen and (max-width: $screen-md-max) {
			font-size: 2.1rem;
			width: 33px;
			height: 33px;
		}

		@media screen and (max-width: $screen-sm-max) {
			font-size: 2rem;
			width: 32px;
			height: 32px;
		}

		@media screen and (max-width: $screen-xs-max) {
			font-size: 1.9rem;
			width: 30px;
			height: 30px;
		}
	}

	.container {
		width: 600px;
		max-width: 100%;
	}

	.loading-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		display: none;

		.fa {
			font-size: 5rem;
		}
	}

	&.js-lightbox-station {
		select {
			margin-bottom: 30px;
			min-width: 150px;
			&.error {
				border: 1px solid $red;
			}
		}
	}
}
















