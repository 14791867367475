table,
.table {
	width: 100%;
	
	&:not(:first-child) {
		margin: 20px 0;
	}

	&.table-vertical {
		tr > td:first-child {
			font-weight: bold;
		}
	}

	&.sorting {
		thead {
			th {
				vertical-align: top;
				padding-right: 20px;
				white-space: nowrap;
				
				&:hover {
					cursor: pointer;

					.th-title {
						&:after {
							opacity: 1;
							transform: rotate(0deg);
						}
					}
				}

				&.sort-active {
					.th-title {
						&:after {
							opacity: 1;
							color: $brand-sec;
							transform: rotate(0deg);
						}
					}
				}

				&.sort-desc {
					.th-title {
						&:after {
							transform: rotate(180deg);
						}
					}
				}
			}

			.th-title {
				position: relative;
				display: inline-block;

				&:after {
					content: '\f0d8';
					font-family: 'FontAwesome';
					font-size: 1.5rem;
					color: $grey;
					// display: inline-block;
					// margin-left: 10px;
					opacity: 0;
					transition: all 0.4s ease-in-out;
					position: absolute;
					top: 5px;
					right: -15px;
					transform: rotate(90deg);

					@media screen and (max-width: $screen-sm-max) {
						top: 3px;
						font-size: 1rem;
					}
				}
			}
		}
	}

	thead {
		border-top: 0;
		border-bottom: 3px solid $grey-light;

		td, th {
			font-size: 2rem;
			font-weight: bold;
			padding: 10px;
			// vertical-align: top!important;

			@media screen and (max-width: $screen-md-max) {
				font-size: 1.9rem;
				padding: 9px;
			}

			@media screen and (max-width: $screen-sm-max) {
				font-size: 1.4rem;
				padding: 8px;
			}

			@media screen and (max-width: $screen-xs-max) {
				font-size: 1.2rem;
				padding: 7px;
			}
		}

		// .th-title {
		// 	display: block;
		// }

		.count {
			display: inline-block;
			font-size: 80%;
			line-height: 1em;
			background: $gold;
			color: white;
			padding: 2px 5px;
			border-radius: 3px;
			position: relative;

			&:after {
				content: '';
				border-style: solid;
				border-width: 5px 5px 0 5px;
				border-color: $gold transparent transparent transparent;
				position: absolute;
				top: 100%;
				left: 4px;
			}
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid $grey-light;

			th,
			td {
				border: 0;
				padding: 4px 8px;
				font-size: 1.8rem;
				vertical-align: middle;

				@media screen and (max-width: $screen-md-max) {
					font-size: 1.7rem;
					padding: 4px 7px;
				}

				@media screen and (max-width: $screen-sm-max) {
					font-size: 1.6rem;
					padding: 4px 6px;
				}

				@media screen and (max-width: $screen-xs-max) {
					font-size: 1.4rem;
					padding: 4px 5px;
				}
			}
		}
	}

	// .td-actions {
	// 	padding: 0;
	// }

	form {
		display: inline-block;
	}
}

.table-responsive {
	@media screen and (max-width: $screen-xs-max) {
		border: 0;
	}
}

.table-hover {
	&.table-row-clickable {
		tbody {
			tr {
				cursor: pointer;
			}
		}
	}
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: $gold;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr:hover > .success,
.table-hover > tbody > tr.success:hover > th {
  background-color: darken($gold, 10%);
}

.table-hover > tbody > tr:hover {
  background-color: $grey;
}