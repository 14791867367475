main {
	padding: 30px;

	@media screen and (max-width: $screen-md-max) {
		padding: 25px;
	}

	@media screen and (max-width: $screen-sm-max) {
		padding: 20px;
	}

	@media screen and (max-width: $screen-xs-max) {
		padding: 15px;
	}
}

.actions {

	@media screen and (max-width: $screen-xs-max) {
		margin-bottom: 20px;
	}

	&.actions-neg {
		margin: -88px 0 0;

		@media screen and (max-width: $screen-md-max) {
			margin: -80px 0 0;
		}

		@media screen and (max-width: $screen-sm-max) {
			margin: -72px 0 0;
		}

		@media screen and (max-width: $screen-xs-max) {
			margin: -65px 0 0;
		}
	}

	.actions-buttons {
		text-align: right;

		@media screen and (max-width: $screen-xs-max) {
			margin-top: 20px;
		}

		.button {
			&:last-child {
				margin-right: 0;
			}
		}
	}
}













