.auth-wrapper {
	width: 100%;
	position: relative;
}

.auth {
	width: 350px;
	max-width: 100%;
	margin: 0 auto;
	
	.title-wrapper {
		margin-bottom: 20px;
	}

	.button {
		margin: 0;
	}

	.panel {
		margin-bottom: 0;
	}

	// .forgot-password {
	// 	text-align: right;
	// }

	.forgot-password-link {
		color: $grey;
		font-size: 1.4rem;
	}
}