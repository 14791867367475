@media print {
  // html, body {
  //   height: auto!important;    
  // }

  // div { border: 1px solid red !important;}

  header,
  .actions,
  .pagination,
  .button,
  table.sorting thead .th-title:after,
  .table.sorting thead .th-title:after,
  table thead .count,
  .table thead .count {
    display: none!important;
  }

  .title-section {
    padding-top: 0;
    padding-bottom: 0;
  }

  table { page-break-inside: auto; }
  tr    { page-break-inside: avoid; page-break-after: auto; }
  thead { display: table-header-group; }
  tfoot { display: table-footer-group; }

  table td {
    padding: 0!important;
  }
}









