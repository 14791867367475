button,
a.button,
.button,
.btn,
input[type="submit"] {
	color: $blue;
	font-size: 1.8rem;
	font-weight: 300;
	background: transparent;
	padding: 10px 20px;
	display: inline-block;
	text-align: center;
	margin: 20px auto;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	border-radius: 0;
	position: relative;
	z-index: 2;
	border: 0;

	@media screen and (max-width: $screen-md-max) {
		font-size: 1.7rem;
		padding: 9px 18px;
		margin: 18px auto;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 1.6rem;
		padding: 8px 16px;
		margin: 16px auto;
	}

	@media screen and (max-width: $screen-xs-max) {
		font-size: 1.5rem;
		padding: 7px 14px;
		margin: 14px auto;
	}

	&:after {
		content: '';
		width: 2px;
		height: 100%;
		background: $gold;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		transition: all 0.2s ease-in-out;
	}

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		background: $grey-light;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		transition: all 0.2s ease-in-out;
	}

	&:hover {
		text-decoration: none;
		color: white;

		&:after {
			width: 100%;
		}
	}

	&.button-grey {
		color: $blue;

		&:before {
			background: $grey-light;
		}

		&:hover {
			color: white;
		}
	}

	&.button-blue {
		color: $grey-light;

		&:before {
			background: $blue;
		}

		&:hover {
			color: white;
		}
	}

	&.button-gold {
		color: $blue;

		&:after {
			background: $blue;
		}

		&:before {
			background: $gold;
		}

		&:hover {
			color: white;
		}
	}

	&.button-red {
		&:after {
			background: $red;
		}
	}

	&.button-green {
		&:after {
			background: $green;
		}
	}

	&.button-contact {
		margin-left: 10px;
	}

	&.button-block {
		display: block;
		width: 100%;
	}

	&.button-margin {
		margin-left: 10px;
		margin-right: 10px;
	}

	&:disabled,
	&.disabled {
		cursor: not-allowed;

		&:before,
		&:after {
			background: $grey;
		}
	}

	&.button-right {
		float: right;
	}

	&.button-icon {
		padding: 3px 10px;
		font-size: 1.4rem;
		margin: 0 10px;
	}

	&.button-input-height {
		margin: 0;
		padding-top: 9px;
		padding-bottom: 8px;
		width: 100%;

		@media screen and (max-width: $screen-xs-max) {
			margin-top: 10px;
		}
	}

	&.button-tools {
		display: inline-block;
		margin: 0 10px;
		padding-top: 9px;
		padding-bottom: 8px;
	}
}


input[type="submit"],
button[type="submit"] {
	border: 0;
}

















