nav {
	ul {
		margin: 0;
		padding: 0;
	}
}

.nav-pri {

	@media screen and (min-width: $screen-sm-min) {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
		display: block!important;
	}

	@media screen and (max-width: $screen-xs-max) {
		display: none;
		text-align: left;
		margin-top: 20px;
	}

	li {
		list-style-type: none;

		@media screen and (min-width: $screen-sm-min) {
			display: inline-block;
		}

		@media screen and (max-width: $screen-xs-max) {
			border-top: 1px solid $blue-dark;
		}
	}

	a {
		padding: 10px 20px;
		display: block;
		color: white;
		font-size: 2.2rem;
		font-weight: 300;
		transition: all 0.2s ease-in-out;

		&:hover,
		&:active,
		&:focus,
		&.active {
			text-decoration: none;
			color: $gold;
			background: none;

			@media screen and (max-width: $screen-xs-max) {
				background: none;
			}
		}

		@media screen and (max-width: $screen-md-max) {
			font-size: 2.1rem;
			padding: 10px 15px;
		}

		@media screen and (max-width: $screen-sm-max) {
			font-size: 2rem;
			padding: 10px 10px;
		}

		@media screen and (max-width: $screen-xs-max) {
			font-size: 1.8rem;
			padding: 10px 20px;
		}
	}

	.current-menu-item {
		& > a {
			color: $gold;

			@media screen and (max-width: $screen-xs-max) {
				font-weight: bold;
			}
		}
	}

	.dropdown-menu {
		background: $gold;
		padding: 5px 20px 0;
		transition: all 0.2s ease-in-out;
		text-align: center;
		border: 0;
		border-radius: 0;
		box-shadow: none;

		@media screen and (min-width: $screen-sm-min) {
			position: absolute;
			top: calc(100% + 16px);
			left: 50%;
			transform: translateX(-50%);
			z-index: 8;
			max-width: 230px;
		}

		@media screen and (min-width: $screen-md-min) {
			top: calc(100% + 23px);
		}

		@media screen and (max-width: $screen-xs-max) {
			position: relative;
			top: initial;
			left: initial;
			float: initial;
			width: 100%;
			margin: 0;
			padding: 0;
			background: $blue-dark;
			text-align: left;
		}

		&.dropdown-menu-side {
			@media screen and (min-width: $screen-sm-min) {
				text-align: center;
				left: initial;
				transform: initial;
				right: -20px;
			}
		}

		li {
			border-bottom: 2px solid $black-20;
			width: 100%;

			&:last-child {
				border-bottom: 0;
			}

			&.current-menu-item {
				a {
					color: $blue;

					@media screen and (max-width: $screen-xs-max) {
						color: $gold;
						font-weight: bold;
					}
				}
			}
		}

		a {
			color: white;
			display: block;
			padding: 10px;
			font-size: 1.8rem;
			font-weight: 300;

			// @media screen and (max-width: $screen-md-max) {
			// 	padding: 0 15px;
			// }

			// @media screen and (max-width: $screen-sm-max) {
			// 	padding: 0 10px;
			// }

			@media screen and (max-width: $screen-xs-max) {
				padding: 10px 10px 10px 40px;
			}

			&:hover {
				background: none;
				text-decoration: none;
				color: $blue;

				@media screen and (max-width: $screen-xs-max) {
					color: $gold;
				}
			}
		}
	}
}

// Nav toggle
// https://codepen.io/designcouch/pen/Atyop
.nav-toggle {
  width: 23px;
  height: 18px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: none;

  @media screen and (max-width: $screen-xs-max) {
    display: block;
    position: absolute;
    top: 25px;
    right: 20px;
  }

  &.open {

    span {
      &:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
    }
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: white;
    // border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 0px;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
      height: 2px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }
}
























