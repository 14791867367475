header {
	width: 100%;
	background: $blue;
	padding: 10px;
	border-bottom: 9px solid $gold;
	position: relative;  
	z-index: 5; 

	@media screen and (max-width: $screen-md-max) {
		border-bottom: 8px solid $gold;
		padding: 9px;
	}

	@media screen and (max-width: $screen-sm-max) {
		border-bottom: 7px solid $gold;
		padding: 8px;
	}

	@media screen and (max-width: $screen-xs-max) {
		border-bottom: 6px solid $gold;
		padding: 7px;
	}

	.logo {
		img {
			@media screen and (max-width: $screen-md-max) {
				width: 200px;
			}

			@media screen and (max-width: $screen-sm-max) {
				width: 175px;
			}

			@media screen and (max-width: $screen-xs-max) {
				width: 150px; 
			}
		}
	}
}

















