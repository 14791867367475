// Disable native datepicker in Google Chrome
// http://stackoverflow.com/questions/11320615/disable-native-datepicker-in-google-chrome
input::-webkit-calendar-picker-indicator{
  display: none;
}

input[type="date"]::-webkit-input-placeholder{ 
  visibility: hidden !important;
}


// .input-group-addon {
// 	background: white;
// 	border: 1px solid lighten($grey, 20%);
// 	color: $blue;
// 	font-size: 1.8rem;
// 	border-radius: 0;
// 	padding-right: 7px;
// }

.bootstrap-datetimepicker-widget {

	&.dropdown-menu {
		width: 310px;
	}

	.list-unstyled {
		list-style: none;

		li {
			list-style: none;
		}
	}

	.picker-switch {
		&.accordion-toggle {
			display: none;
		}
	}

	thead {
		padding-bottom: 5px;
	}

	table {
		td {
			&.today {
				border-radius: 0;
				font-weight: bold;

				&:before {
					display: none;
				}
			}

			span.active {
				background: $blue;
				color: $gold;
				font-weight: bold;
			}

			&:not(:first-child) {
				border-left: 1px solid $grey-light;
			}

			&.new,
			&.old {
				color: $grey;
			}

			&.active,
			&.active:hover {
				background: $blue;
				color: $gold;
				font-weight: bold;
			}

			&.disabled,
			&.disabled:hover {
				color: $grey-light;
			}
		}
	}

	.dow {
		font-weight: bold;
		color: $gold;
	}

	table td.active,
	table td.active:hover,
	table td.day:hover,
	table td.hour:hover,
	table td.minute:hover,
	table td.second:hover,
	table thead tr:first-child th:hover,
	table td,
	table th,
	table td span {
		border-radius: 0;
	}
}

