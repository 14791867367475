body {
	font-family: $font-primary;
	background: white;
	position: relative;
	color: $blue;
	font-size: 1.8rem;
	font-weight: 300;

	&.no-scroll {
		overflow: hidden;
	}
}

h1,
.title {
	font-size: 4.8rem;
	font-weight: 300;
	margin-bottom: 15px;

	@media screen and (max-width: $screen-md-max) {
		font-size: 4.4rem;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 4rem;
	}

	@media screen and (max-width: $screen-xs-max) {
		font-size: 3.5rem;
	}

	&:first-child {
		margin-top: 0;
	}

	&.title-small {
		font-size: 2.5rem;

		@media screen and (max-width: $screen-md-max) {
			font-size: 2rem;
		}

		@media screen and (max-width: $screen-sm-max) {
			font-size: 1.8rem;
		}

		@media screen and (max-width: $screen-xs-max) {
			font-size: 1.5rem;
		}
	}

	.flight-number {
		display: block;
		line-height: 1.2em;
	}

	.date {
		font-size: 2rem;
		line-height: 1.2em;
		color: $gold;
		display: block;
	}
}

h2 {
	font-size: 3rem;
	font-weight: 400;
	margin-top: 30px;

	@media screen and (max-width: $screen-md-max) {
		font-size: 2.8rem;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 2.6rem;
	}

	@media screen and (max-width: $screen-xs-max) {
		font-size: 2.4rem;
	}
}

h3 {
	font-size: 2.4rem;
	font-weight: 400;
	margin-top: 30px;

	@media screen and (max-width: $screen-md-max) {
		font-size: 2.2rem;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 2rem;
	}

	@media screen and (max-width: $screen-xs-max) {
		font-size: 1.8rem;
	}
}

h6 {
	font-weight: 300;
}

a, p, li, td, th {
	font-size: 1.9rem;
	font-weight: 300;
	color: $blue;

	@media screen and (max-width: $screen-md-max) {
		font-size: 1.8rem;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 1.7rem;
	}

	@media screen and (max-width: $screen-xs-max) {
		font-size: 1.6rem;
	}
}

blockquote {
	font-size: 2.4rem;

	@media screen and (max-width: $screen-md-max) {
		font-size: 2.2rem;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 2rem;
	}

	@media screen and (max-width: $screen-xs-max) {
		font-size: 1.8rem;
	}
}

ul {
	margin-bottom: 0;
}

ul > li {
	list-style: square;
}

ul > li > ul > li {
	list-style: circle;
}

a {
	transition: all 0.2s ease-in-out;

	&:focus {
	  color: $grey-dark;
	  text-decoration: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 3px solid $grey-light;
  margin: 20px 0;
  padding: 0; 
}

iframe {
	border: 0;
}

.title-section {
	background: $blue;
	border-left: 2px solid rgba(0,0,0,0.2);
	color: white;
	font-size: 2rem;
	font-weight: 400;
	padding: 10px;
	margin-top: 15px;

	@media screen and (max-width: $screen-md-max) {
		font-size: 1.9rem;
	}

	@media screen and (max-width: $screen-sm-max) {
		font-size: 1.8rem;
	}

	@media screen and (max-width: $screen-xs-max) {
		font-size: 1.7rem;
	}

	& + table {
		margin-top: 0!important;
	}

	& + .actions {
		margin-top: 30px;
	}
}













