
// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$red: 							#c01e2e;
$red-dark: 					#740f1a;
$blue-light: 				#36497d;
$blue: 							#202b4b;
$blue-dark: 				#111b31;
$orange: 						#fc4513;
$green: 						#1ec031;
$purple: 						#91268f;
$beige: 						#ede4d3;
$gold: 							#e0b780;

$grey-light: 				#f1f1f1;
$grey: 							#c5c5c5;
$grey-dark: 				#303030;
$black-10: 					rgba(0,0,0,0.1);
$black-20: 					rgba(0,0,0,0.2);
$black-30: 					rgba(0,0,0,0.3);

$brand-primary: $grey-dark;
$brand-sec: $blue;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-primary: 'Source Sans Pro', sans-serif;
$font-size-base: 18px;
$line-height-base: 1.6;
$text-color: $brand-primary;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
