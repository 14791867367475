.boardingpass {
	page-break-after: always;
	width: 762px;
	// width: 612px;
	// height: 792px;
	margin: 0 auto;
	position: relative;

	.boardingpass-bg {
		width: 100%;

	  .boardingpass-bg-image {
	  	width: 100%;
	  }
	}

	.boardingpass-content {
		width: 88%;
		height: 288px;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;

		&.boardingpass-content-1 { top: 152px; }
		&.boardingpass-content-2 { top: 433px; }
		&.boardingpass-content-3 { top: 715px; }
	}

	.text {
		display: block;
		font-weight: bold;
		position: absolute;
	}

	.number {
		font-family: "Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace;
		font-size: 1.4rem;
		font-weight: 400;
		color: #d2232a!important;
	}

	.boardingpass-content-big {
		.destination-start {
			top: 40px;
			left: 0;
		}

		.destination-arrival {
			top: 40px;
			left: 236px;
		}

		.time-boarding {
			top: 83px;
			left: 0;
		}

		.time-leave {
			top: 128px;
			left: 0;
		}

		.flight {
			top: 83px;
			left: 193px;
		}

		.gate {
			top: 83px;
			left: 345px;
		}

		.seat {
			top: 128px;
			left: 345px;
		}

		.date {
			top: 128px;
			left: 193px;
		}

		.number {
			top: 156px;
			left: 20px;
		}
	}

	.boardingpass-content-small {
		width: 150px;
		height: 200px;
		position: absolute;
	  top: -10px;
	  left: 535px;

	  .text {
	  	left: 0;
	  	font-size: 1.1rem;
	  }

		.destination-start {
			top: 26px;
		}

		.destination-arrival {
			top: 52px;
		}

		.time-boarding {
			top: 80px;
		}

		.time-leave {
			top: 105px;
		}

		.flight {
			top: 160px;
		}

		.gate {
			top: 132px;
		}

		.seat {
			top: 132px;
			left: 50px;
		}

		.date {
			top: 160px;
			left: 70px;
		}

		.number {
			top: 180px;
			left: 20px;
		}
	}
}


@media print {}


















