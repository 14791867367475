

/* ----------------------------------------
** GENERAL
---------------------------------------- */

form {
	text-align: left;

	&.form-inline-block {
		display: inline-block;
	}

	&.form-horizontal {
		.control-label {
			@media screen and (min-width: $screen-sm-min) {
				width: 150px;
				float: left;
				position: relative;
				display: inline-block;
			}
		}

		.checkbox {
			.control-label {
				text-align: left;
				padding-top: 0;
				min-height: initial;
			}
		}
	}

	legend {
		font-size: 2rem;

		@media screen and (max-width: $screen-md-max) {
			font-size: 1.9rem;
		}

		@media screen and (max-width: $screen-sm-max) {
			font-size: 1.8rem;
		}

		@media screen and (max-width: $screen-xs-max) {
			font-size: 1.7rem;
		}
	}

	.form-group {
		@media screen and (max-width: $screen-xs-max) {
			margin-bottom: 5px;
		}
	}

	label {
		font-weight: 300;
		margin: 0;
		display: block;

		@media screen and (max-width: $screen-xs-max) {
			font-size: 1.4rem;
		}
	}

	textarea {
		resize: vertical;
	}

	.validation-errors {
		border: 0;
		padding: 20px;
		background: white;
		margin: 20px 0 0;
		font-size: 1.6rem;

		&:before {
			font-family: 'FontAwesome';
		  content: "\f06a";
		  color: $blue;
		  margin-right: 5px;
		}
	}

	.response-output {
		border: 0;
		padding: 20px;
		background: white;
		margin: 20px 0 0;
		font-size: 1.6rem;
	}

	.mail-sent-ok {
		border: 0;
		padding: 20px;
		background: white;
		margin: 20px 0 0;
		font-size: 1.6rem;

		&:before {
			font-family: 'FontAwesome';
		  content: "\f058";
		  color: $green;
		  margin-right: 5px;
		}
	}

	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="date"],
	input[type="search"],
	input[type="password"],
	input[type="file"],
	select,
	textarea {
		width: 100%;
		color: $grey-dark;
		border: 0;
		padding: 10px 15px;
		outline: none;
		transition: all 0.2s ease-in-out;
		border-radius: 0;
		background: $grey-light;
		font-size: 1.6rem;
		font-weight: 400;

		@media screen and (max-width: $screen-md-max) {
			padding: 9px 15px;
		}

		@media screen and (max-width: $screen-sm-max) {
			padding: 8px 15px;
		}

		@media screen and (max-width: $screen-xs-max) {
			padding: 7px 15px;
		}

		&:focus {
			box-shadow: 0 0 1px $grey;
		}
	}

	input[type="date"] {
		@media screen and (max-width: $screen-sm-max) {
			min-height: 34px;
		}
	}

	textarea {
		width: 100%;
		height: 100px;
	}

	.form-date {
		position: relative;
		display: block;
		background: $grey-light;

		&:before {
			font-family: 'FontAwesome';
			content: '\f073';
			color: $blue;
			font-size: 2.5rem;
			position: absolute;
			top: 5px;
			right: 5px;
			z-index: 3;
		}
	}

	.form-select {
		position: relative;
		display: block;
		background: $grey-light;

		&:not(.form-select-multipe) {
			&:before {
				font-family: 'FontAwesome';
				content: '\f13a';
				color: $blue;
				font-size: 2rem;
				position: absolute;
				top: 50%;
				right: 10px;
				transform: translateY(-50%);
				z-index: 3;
				transition: all 0.2s ease-in-out;
			}

			&.form-select-hover {
				&:before {
					opacity: 0;
				}

				&:hover {
					&:before {
						opacity: 1;
					}
				}
			}
		}

		&.form-select-multipe {
			select {
				padding-right: 0;
			}
			
			option:checked {
				position: relative;

				&:after {
					font-family: 'FontAwesome';
					content: '\f058';
					color: $blue;
					font-size: 2rem;
					position: absolute;
					top: 50%;
					right: 10px;
					transform: translateY(-50%);
					z-index: 3;
					transition: all 0.2s ease-in-out;
				}
			}
		}
	}

	select,
	input[type="date"] {
		position: relative;
		z-index: 4;
		appearance: none;
		border-radius: 0px;
		cursor: pointer;
		background: transparent;
		padding-right: 30px;
	}

	option {
		color: $grey-dark;
	}

	p {
		margin: 5px 0 0;
	}

	.not-valid-tip {
		color: black;
		background: $grey-light;
		padding: 5px;
	  box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);

		&:before {
			font-family: 'FontAwesome';
		  content: "\f06a";
		  color: $blue;
		  margin-right: 5px;
		}
	}

	&#loginform {
		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="date"],
		input[type="search"],
		input[type="password"],
		select,
		textarea {
			background: $grey-light;
		}

		.login-remember {
			font-size: 1.6rem;
			margin-top: 10px;
		}
	}

}

/* IE fix */
select::-ms-expand {
    display: none;
}

/* ----------------------------------------
** SEARCH
---------------------------------------- */

.form-search {
	position: relative;

	.date-search {
		@media screen and (min-width: $screen-sm-min) {
			display: inline-block;
			position: absolute;
			top: 0;
			z-index: 1;
			right: 60px;
		}
	}

	.date-input {
		padding-left: 30px;
		padding-right: 0;
		width: 155px;

		@media screen and (max-width: $screen-xs-max) {
			font-size: 1.3rem;
			width: 120px;
		}

		&:before {
			content: '\f073';
			font-family: 'FontAwesome';
			font-size: 2rem;
			color: $brand-sec;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
	}

	.date-search-min,
	.date-search-max,
	.date-to {
		display: inline-block;
	}

	.date-search-min {
		@media screen and (max-width: $screen-xs-max) {
			padding-left: 15px;
		}
	}

	.date-search-max {
		@media screen and (max-width: $screen-xs-max) {
			padding-left: 10px;
		}
	}

	.date-to {
		font-size: 1.1rem;
		font-weight: bold;
		text-transform: uppercase;
		margin-right: 40px;

		@media screen and (max-width: $screen-xs-max) {
			margin-right: 0;
		}
	}

	.button-search {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		padding-top: 9px;
		padding-bottom: 8px;

		@media screen and (max-width: $screen-md-max) {
			padding: 8px 15px;
		}

		@media screen and (max-width: $screen-sm-max) {
			padding: 8px 15px;
		}

		@media screen and (max-width: $screen-xs-max) {
			padding: 7px 15px 8px;
		}
	}

	.form-select {
		@media screen and (max-width: $screen-xs-max) {
			margin-top: 5px;
		}
	}
}

/* ----------------------------------------
** CHECKBOX + RADIO
---------------------------------------- */

form {
	.fake-label {
		display: none;
	}

	input[type=checkbox],
	input[type=radio] {
		display: none;
		text-align: left;

		& + label {
			position: relative;
			padding-left: 30px;
			display: inline-block;

			&:before {
			  content: '';  
			  width: 18px;
			  height: 18px;  
			  position: absolute;
			  top: 50%;
			  left: 0;
			  transform: translateY(-50%);
			  background: $grey-light;
			}
		}

		&:checked {
			& + label {
				&:before {
					font-family: 'FontAwesome';
				  content: '\f00c';
				  font-size: 1.6rem;
				  color: $brand-sec;
				  text-align: center;
				  line-height: 19px;
				}
			}
		}
	}

	input[type=radio] {
		& + label {
			&:before {
			  border-radius: 50%;
			}
		}

		&:checked {
			& + label {
				&:before {
				  content: '\f111';
				  font-size: 1.3rem;
				}
			}
		}
	}
}



























