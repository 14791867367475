.report {
  
  th, td, a, p {
    font-size: 1.2rem!important;
  }

  th {
    padding: 5px 0!important;
  }

  h1 {
    page-break-before: always;
  }

  h1, h2, h3, h4, h5 {
    page-break-after: avoid;
  }

  table, figure {
    page-break-inside: avoid;
  }
}