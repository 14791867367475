
// Fonts
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,300italic,300,400italic,600italic,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "~font-awesome/scss/font-awesome.scss";


@import "common/global";
@import "common/print";

@import "components/buttons";
@import "components/datepicker";
@import "components/forms";
@import "components/grid";
@import "components/nav";
@import "components/search";
@import "components/tables";
@import "components/alert";
@import "components/auth";
@import "components/lightbox";
@import "components/boardingpass";

@import "layouts/header";
@import "layouts/home";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/report";

@import "node_modules/eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker";