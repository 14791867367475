.alert {
	button {
		margin: 0;

		&:before,
		&:after, {
			display: none;
		}
	}

	ul {
		padding: 0;
	}

	li {
		list-style: none;
	}
}